import { extendTheme, Tag, withDefaultColorScheme, withDefaultProps } from "@chakra-ui/react";
import { mode, StyleFunctionProps } from "@chakra-ui/theme-tools";

// Foundational style overrides
// import borders from './foundations/borders'

// Component style overrides
// import { Steps } from "./components/steps";

/**
 * Theme Variables to use everywhere where it is needed.
 *
 */
export const themeVariables = {
  units: {
    borderRadius: "6px",
    boxShadowLight: "0 3px 3px 0 rgba(43, 43, 43, 0.3)"
  }
};

/**
 * Overrides directly the chakra variables.
 *
 */
const overrides = {
  styles: {
    global: (props: StyleFunctionProps) => ({
      body: {
        fontFamily: "Assistant",
        fontStyle: "normal",
        fontOpticalSizing: "auto",
        letterSpacing: "-0.0125em",
        color: mode("gray.800", "whiteAlpha.900")(props),
        bg: mode("gray.50", "gray.800")(props),
        lineHeight: "base"
      }
    })
  },
  colors: {
    lightBlue: {
      50: "#d8f9ff",
      100: "#abe9ff",
      200: "#7bdaff",
      300: "#48caff",
      400: "#1abaff",
      500: "#00a1e6",
      600: "#007db4",
      700: "#005982",
      800: "#003751",
      900: "#001421"
    },
    darkBlue: {
      50: "#e5ecf3",
      100: "#465d7a",
      200: "#4e79a4",
      300: "#3d6082",
      400: "#355370",
      500: "#2d465f",
      600: "#25394e",
      700: "#1d2d3c",
      800: "#14202b",
      900: "#0c131a"
    },
    cta: {
      50: "#f07c0056",
      500: "#F07D00",
      600: "#D16600"
    }
  },
  components: {}
};

/**
 * Create and export the theme.
 *
 */

const buttonStyle = {
  baseStyle: {
    borderRadius: "3xl" // or any other desired borderRadius value
  }
};
const tooltipStyle = {
  baseStyle: {
    borderRadius: "2xl" // or any other desired borderRadius value
  }
};

let theme = extendTheme(
  overrides,
  withDefaultColorScheme({ colorScheme: "darkBlue" }),

  {
    components: {
      Button: buttonStyle,
      Tag: buttonStyle,
      Tooltip: tooltipStyle
      // Add other components and their default styles here
    }
  }
);

export { theme };
