import { Membership } from "./membership";
import { IProduct } from "./product";

export interface IOrganisation {
  id: number;
  name: string;
  memberships: Array<Membership>;
  organisationType: number;
  ownedProducts?: Array<IProduct>;
}
export interface ITempUser {
  email: string;
  isAdministrator: boolean;
}
export class Organisation implements IOrganisation {
  id: number;

  name: string;

  memberships: Array<Membership>;

  organisationType: number;

  constructor(data: Record<string, unknown>) {
    this.id = Number(data.id);
    this.name = String(data.name);
    this.memberships = data.memberships as Array<Membership>;
    this.organisationType = Number(data.organisationType);
  }
}
