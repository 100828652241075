import _ from "lodash";

interface IPaginationData {
  hasNext: boolean;
  hasPrevious: boolean;
  nextQueryParams: URLSearchParams;
  previousQueryParams: URLSearchParams;
}

/**
 * Generic response type for list views.
 *
 */
export type ListResponse<T> = {
  count: number;
  next: string;
  previous: string;
  results: Array<T>;
};

export type ListResponseWithInformation<T> = {
  count: number;
  hasNext: boolean;
  hasPrevious: boolean;
  data: Array<T>;
};

/**
 * Checks whether the data object has a `next` or `previous` key in it.
 * If yes, extract the query params and return the state, that there is
 * more to load and return the query params.
 *
 */
export function getPaginationInformation<T>(data: ListResponse<T>): IPaginationData {
  let hasNext = !_.isEmpty(data.next);

  let hasPrevious = !_.isEmpty(data.previous);
  let nextQueryParams = new URLSearchParams({});
  let previousQueryParams = new URLSearchParams({});

  const nextUrl = String(_.get(data, "next", ""));
  const previousUrl = String(_.get(data, "previous", ""));

  if (hasNext) {
    const nextIndexOfQuestionMarker = nextUrl.indexOf("?");
    if (nextIndexOfQuestionMarker) {
      const nextParams = nextUrl.slice(nextIndexOfQuestionMarker);
      hasNext = true;
      nextQueryParams = new URLSearchParams(nextParams);
    }
  }

  if (hasPrevious) {
    const previousIndexOfQuestionMarker = previousUrl.indexOf("?");
    if (previousIndexOfQuestionMarker) {
      const previousParams = previousUrl.slice(previousIndexOfQuestionMarker);
      hasPrevious = true;
      previousQueryParams = new URLSearchParams(previousParams);
    }
  }

  return {
    hasNext: hasNext,
    hasPrevious: hasPrevious,
    nextQueryParams: nextQueryParams,
    previousQueryParams: previousQueryParams
  };
}

/**
 * Generic function to fetch paginated data from the server.
 *
 * Usage:
 * const data = getPaginatedData<MyClass>(fetchContent)
 */
export async function getPaginatedDataAndInformation<T>(
  callback: Function,
  pageParams: URLSearchParams = new URLSearchParams({}),
  data: T[] = []
): Promise<ListResponseWithInformation<T>> {
  const res = await callback(pageParams);
  const paginationInformation = getPaginationInformation(res.data);
  data?.push(...res.data?.results);

  return {
    count: res.data.count,
    hasPrevious: paginationInformation.hasPrevious,
    hasNext: paginationInformation.hasNext,
    data: data
  };
}

/**
 * Generic function to fetch paginated data from the server.
 *
 * Usage:
 * const data = getPaginatedData<MyClass>(fetchContent)
 */
export async function getPaginatedData<T>(
  callback: Function,
  data: T[] = [],
  pageParams: URLSearchParams = new URLSearchParams({})
): Promise<T[]> {
  const res = await callback(pageParams);
  if (res.status === 200) {
    const paginationInformation = getPaginationInformation(res.data);

    data.push(...res.data?.results);

    if (paginationInformation.hasNext) {
      data = await getPaginatedData<T>(callback, data, paginationInformation.nextQueryParams);
    }
  }
  return data;
}

/**
 * TEMP:
 * Only get first page of any resource
 *
 * Usage:
 * const data = getPaginatedData<MyClass>(fetchContent)
 */
export async function getPaginatedDataFPOnly<T>(
  callback: Function,
  data: T[] = [],
  pageParams: URLSearchParams = new URLSearchParams({})
): Promise<T[]> {
  const res = await callback(pageParams);
  if (res.status === 200) {
    data.push(...res.data?.results);
  }
  return data;
}
