import React, { createContext, useEffect, useMemo, useState } from "react";
import { useToast } from "@chakra-ui/react";
import { useRouter } from "next/router";

import { WebsitePath } from "../constants";
import { User } from "../models/user";
import { IOrganisation } from "../models/organisation";
import { ApiUserService } from "../services/http/apiUserService";
import { ApiSocialAuthentication } from "../services/http/apiSocialAuthentication";
import { browserStorage, deleteBrowserData } from "../storage/localStorage";

interface IAuthContextStates {
  user: null | User;
  organisations: Array<IOrganisation>;
  currentOrganisation: null | IOrganisation;
  setUser: Function;
  setOrganisations: Function;
  setCurrentOrganisation: Function;
  isSocialUser: boolean;
  isKMZUser: boolean;
  isAuthenticated: boolean;
  setIsAuthenticated: Function;
  updateUser: Function;
  doLogin: Function;
  doLogout: Function;
  doLogoutToSDenial: Function;
  onLoginSuccess: Function;
  ensureAuthenticated: Function;
  ensureAdministrator: Function;
  remainingLoginAttempts: number;
  refreshUser: Function;
}

/**
 * Creates the context.
 *
 */
const AuthContext = createContext<IAuthContextStates>({
  user: null,
  organisations: [],
  currentOrganisation: null,
  setUser: (user: User) => {},
  setOrganisations: (organisation: Array<IOrganisation>) => {},
  setCurrentOrganisation: (organisation: IOrganisation) => {},
  isAuthenticated: false,
  isSocialUser: false,
  isKMZUser: false,
  setIsAuthenticated: (isAuthenticated: boolean) => {},
  updateUser: (user: User) => {},
  refreshUser: () => {},
  doLogin: (email: string, password: string) => {},
  doLogout: () => {},
  doLogoutToSDenial: () => {},
  onLoginSuccess: () => {},
  ensureAuthenticated: () => {},
  ensureAdministrator: () => {},
  remainingLoginAttempts: 3
});

/**
 * Wrapper around the application to provide login context of the user.
 *
 */
export const AuthProvider: React.FC<React.PropsWithChildren> = props => {
  const router = useRouter();
  const toast = useToast();
  const [user, setUser] = useState<User | null>(browserStorage.getUser());
  const [organisations, setOrganisations] = useState<Array<IOrganisation>>([]);
  const [currentOrganisation, setCurrentOrganisation] = useState<IOrganisation | null>(null);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(user?.id! > 0);
  const [remainingLoginAttempts, setRemainingLoginAttempts] = useState(3);

  /**
   * This useEffect is here to fetch the social auth config data. We need this data
   * everytime. Also, this call will help us to ensure the csrf token is available.
   *
   */
  useEffect(() => {
    ApiSocialAuthentication.getConfig();
  }, []);

  /**
   * Login the user.
   *
   * @param email
   * @param password
   */
  async function doLogin(email: string, password: string): Promise<void> {
    if (remainingLoginAttempts > 0) {
      const sessionAxiosResponse = await ApiUserService.loginUser(email, password);
      if (sessionAxiosResponse.status === 201) {
        browserStorage.setAccessToken(sessionAxiosResponse.data.access);
        browserStorage.setRefreshToken(sessionAxiosResponse.data.refresh);
        await onLoginSuccess();
        setRemainingLoginAttempts(3);
      } else {
        loginError();
      }
    }
  }

  /**
   * Logout user.
   *
   */
  function doLogout(): void {
    if (router.pathname === WebsitePath.CONTENT) {
      router.reload();
    } else {
      router.push(WebsitePath.CONTENT);
    }
    clearUserData();
    toast({
      title: "Logout",
      description: "Erfolgreich ausgeloggt.",
      status: "success",
      duration: 5000,
      isClosable: true
    });
  }

  /**
   * Logout user.
   *
   */
  function doLogoutToSDenial(): void {
    if (router.pathname === WebsitePath.CONTENT) {
      router.reload();
    } else {
      router.push(WebsitePath.CONTENT);
    }
    clearUserData();
    toast({
      title: "Sie werden ausgeloggt",
      status: "warning",
      description: "Die Benutzung des Portals ist nur möglich, wenn Sie die Nutzungsbedingungen akzeptieren.",
      duration: 5_000,
      isClosable: true
    });
  }

  function clearUserData(): void {
    try {
      ApiUserService.logoutUser();
    } catch (e) {
      console.error("Could not logout user: ", e);
    }
    deleteBrowserData();
    setUser(null);
    setIsAuthenticated(false);
  }

  /**
   * Refresh User from backend.
   *
   */
  async function refreshUser() {
    const res = await ApiUserService.getMyself();
    if (res.status === 200) {
      browserStorage.setUser(res.data);
      const tmpUser = browserStorage.getUser();
      setUser(tmpUser);
      setIsAuthenticated(true);
      // TODO: Check also, whether a token is already here!
      if (tmpUser.isSocialUser) {
        const resObtainToken = await ApiUserService.obtainTokenForSocialUser();
        if (resObtainToken.status === 201) {
          browserStorage.setAccessToken(resObtainToken.data.access);
          browserStorage.setRefreshToken(resObtainToken.data.refresh);
          document.cookie = "sessionid=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        }
      }
    } else {
      loginError();
    }
  }

  /**
   * Handle when the user was able to log in successfully.
   *
   */
  async function onLoginSuccess() {
    refreshUser();
    router.push("/library");
    toast({
      title: "Login",
      description: "Erfolgreich eingeloggt.",
      status: "success",
      duration: 5000,
      isClosable: true
    });
  }

  /**
   * Login was not successful.
   *
   */
  function loginError() {
    let newAttempts = remainingLoginAttempts;
    newAttempts -= 1;
    setRemainingLoginAttempts(newAttempts);
    deleteBrowserData();
    setUser(null);
    setIsAuthenticated(false);
    if (newAttempts > 0) {
      toast({
        title: "Login nicht möglich",
        description: "Bitte prüfen Sie die eingegebene E-Mail und Ihr Passwort.",
        status: "error",
        duration: 5000,
        isClosable: true
      });
    } else {
      toast({
        title: "Login gesperrt",
        description: "Aus Sicherheitsgründen wurde der Login temporär gesperrt",
        status: "error",
        duration: 5000,
        isClosable: true
      });
    }
  }

  /**
   * Ensure user is authenticated. Otherwise, redirect to "/".
   *
   */
  function ensureAuthenticated() {
    if (!isAuthenticated) {
      router.push("/login");
      toast({
        title: "Sie sind nicht eingeloggt",
        description: "Bitte loggen Sie sich ein.",
        status: "warning",
        duration: 5_000,
        isClosable: true
      });
      return false;
    }
    return true;
  }

  function ensureAdministrator() {
    if (!ensureAuthenticated()) return;
    if (user && !user.isAdministratorOfCurrentOrganisation()) {
      router.push("/library");
      toast({
        title: "Begrenzter Zugriff",
        description: "Dieser Bereich kann nur von Administratoren benutzt werden.",
        status: "error",
        duration: 5_000,
        isClosable: true
      });
    }
  }

  function updateUser(user: User) {
    browserStorage.setUser(user);
    setUser(browserStorage.getUser());
    setIsAuthenticated(true);
  }

  const isSocialUser = useMemo(() => {
    if (!user) return false;
    return user.isSocialUser || (user.organisations?.length > 0 && user.organisations[0].organisationType == 5);
  }, [user]);

  const isKMZUser = useMemo(() => {
    if (!user) return false;
    return user.organisations?.length > 0 && user.organisations[0].organisationType == 4;
  }, [user]);

  return (
    <AuthContext.Provider
      value={{
        user,
        refreshUser,
        setUser,
        organisations,
        currentOrganisation,
        setOrganisations,
        setCurrentOrganisation,
        isAuthenticated,
        isSocialUser,
        isKMZUser,
        setIsAuthenticated,
        updateUser,
        doLogin,
        doLogout,
        doLogoutToSDenial,
        onLoginSuccess,
        ensureAuthenticated,
        ensureAdministrator,
        remainingLoginAttempts
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export function useAuth(): IAuthContextStates {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within a AuthContext.");
  }

  return context;
}
