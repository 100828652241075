import { BasicObject } from "../constants";
import { IDevice } from "./device";

export interface IWifiConfig {
  name: string;
  wlanPassword: string;
  wlanSsid: string;
  showPassword: boolean;
}
export enum ProductSortingString {
  DEFAULT = "",
  ALPHABET_ASC = "alphabetial_asc",
  ALPHABET_DESC = "alphabetial_desc",
  OWNER_ALPHABET_ASC = "owner_alphabetial_asc",
  OWNER_ALPHABET_DESC = "owner_alphabetial_desc"
}

export interface IProduct {
  id: number;
  appVersion: string;
  color: string;
  positionNumber: number;
  devices: IDevice[];
  serialNumber: string;
  isOnline?: boolean;
  name: string;
  owner: BasicObject;
  inPossession?: BasicObject;
  productType?: string;
  router?: IWifiConfig;
}
