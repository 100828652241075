import { AxiosResponse } from "axios";

import { ISession } from "../../models/session";
import { IUserPatch, User } from "../../models/user";
import { dispatch } from "./http";

export interface ICurrentToS {
  createdAt: string;
  id: number;
  owner: number;
  tosFile: string;
  updatedAt: string;
  version: string;
}
export interface ICurrentPP {
  createdAt: string;
  id: number;
  owner: number;
  privacyPolicyFile: string;
  updatedAt: string;
  version: string;
}
export class ApiUserService {
  public static getMyself(): Promise<AxiosResponse<User>> {
    return dispatch({
      method: "GET",
      url: "/api/v1/users/me/"
    });
  }

  public static getMyToS(): Promise<AxiosResponse<ICurrentToS>> {
    return dispatch({
      method: "GET",
      url: "/api/v1/organisations/current-tos/"
    });
  }

  public static getMyPP(): Promise<AxiosResponse<ICurrentPP>> {
    return dispatch({
      method: "GET",
      url: "/api/v1/organisations/current-pp/"
    });
  }

  /**
   * ************************************************
   * SESSIONS
   * ************************************************
   */
  public static loginUser(username: string, password: string): Promise<AxiosResponse<ISession>> {
    return dispatch({
      method: "POST",
      url: "/api/v1/sessions/",
      data: {
        username: username,
        password: password
      }
    });
  }

  public static logoutUser(): Promise<AxiosResponse<void>> {
    return dispatch({
      method: "POST",
      url: "/api/v1/sessions/logout"
    });
  }

  public static obtainTokenForSocialUser(): Promise<AxiosResponse<ISession>> {
    return dispatch({
      method: "GET",
      url: "/api/v1/sessions/social-user-token-obtain/"
    });
  }

  /**
   * ************************************************
   * USERS ME
   * ************************************************
   */
  static deleteMyself(): Promise<AxiosResponse> {
    return dispatch({
      method: "DELETE",
      url: "/api/v1/users/me/"
    });
  }

  public static acceptToS(): Promise<AxiosResponse> {
    return dispatch({
      method: "POST",
      url: "/api/v1/users/tos/",
      data: {}
    });
  }

  public static patchMyself(patchedUserFields: Partial<IUserPatch>, jwtToken?: string): Promise<AxiosResponse<User>> {
    return dispatch({
      method: "PATCH",
      url: "/api/v1/users/me/",
      jwtToken: jwtToken,
      data: patchedUserFields
    });
  }

  public static resetPassword(newPassword: string, urlHash: string): Promise<AxiosResponse<any>> {
    return dispatch({
      method: "POST",
      url: "/api/v1/users/reset-password/",
      data: {
        password: newPassword,
        urlHash
      }
    });
  }

  public static resetPasswordEmail(email: string): Promise<AxiosResponse<any>> {
    return dispatch({
      method: "POST",
      url: "/api/v1/users/reset-password-mail/",
      data: { email }
    });
  }
}
