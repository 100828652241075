import React, { createContext, useEffect, useState } from "react";

import { User } from "../models/user";

import { browserStorage, deleteBrowserData } from "../storage/localStorage";
import { IOrganisation } from "../models/organisation";
import { getPaginatedData } from "../utils/http";
import { IContent, ILibraryContent } from "../models/content";
import { ApiLibraryContentService } from "../services/http";
import { use } from "i18next";

interface ISharedContentStates {
  user: null | User;
  organisations: Array<IOrganisation>;
  currentOrganisation: null | IOrganisation;
  setUser: Function;
  localContent: ILibraryContent[];
  getSharedContent: Function;
  setOrganisations: Function;
  setCurrentOrganisation: Function;
}

/**
 * Creates the context.
 *
 */
const SharedContentContext = createContext<ISharedContentStates>({
  user: null,
  organisations: [],
  currentOrganisation: null,
  localContent: [],
  setUser: (user: User) => {},
  getSharedContent: () => {},
  setOrganisations: (organisation: Array<IOrganisation>) => {},
  setCurrentOrganisation: (organisation: IOrganisation) => {}
});

/**
 * Wrapper around the application to provide login context of the user.
 *
 */
export const SharedContentProvider: React.FC<React.PropsWithChildren> = props => {
  const [user, setUser] = useState<User | null>(browserStorage.getUser());
  const [organisations, setOrganisations] = useState<Array<IOrganisation>>([]);
  const [currentOrganisation, setCurrentOrganisation] = useState<IOrganisation | null>(null);
  const [localContent, setLocalContent] = useState<Array<ILibraryContent>>([]);

  const getSharedContent = (): ILibraryContent[] => {
    if (localContent.length === 0 && user) {
      getPaginatedData<ILibraryContent>(ApiLibraryContentService.fetchAllContent).then((content: ILibraryContent[]) => {
        let filtredContent: ILibraryContent[] = [];
        if (content.length > 0) {
          content.sort((a, b) => {
            if (a.createdAt && b.createdAt) {
              if (a.createdAt < b.createdAt) {
                return 1;
              }
              if (a.createdAt > b.createdAt) {
                return -1;
              }
            }
            return 0;
          });

          filtredContent = content.filter(content => {
            return content.owner!.id != 1;
          });
        }

        setLocalContent(filtredContent);

        return filtredContent;
      });
    }
    return localContent;
  };

  /**
   * Ensure user is authenticated. Otherwise redirect to "/".
   *
   */

  return (
    <SharedContentContext.Provider
      value={{
        user,
        setUser,
        localContent,
        getSharedContent,
        organisations,
        currentOrganisation,
        setOrganisations,
        setCurrentOrganisation
      }}
    >
      {props.children}
    </SharedContentContext.Provider>
  );
};

export function useSharedContent(): ISharedContentStates {
  const context = React.useContext(SharedContentContext);
  if (context === undefined) {
    throw new Error("useSharedContent must be used within a sharedContentContext.");
  }

  return context;
}
