import React, { createContext, useEffect, useMemo, useState } from "react";
import { ApiDeviceService } from "../services/http";
import { CloudCommand } from "../constants";
import { IDevice } from "../models/device";
import { getVideoDuration } from "../utils/helper";
import { browserStorage } from "../storage/localStorage";
import { ContentType, ILibraryContent } from "../models/content";

// Needs to be created outside the context to reset on rerender.
let timerInterval: any;
// Avoids js closures ... Actually not found a better way of doing it
let isPausedGlobal: boolean;

export interface IPlaybackContextStates {
  isPlaying: boolean;
  isPaused: boolean;
  currentTime: number;
  currentContent: ILibraryContent | undefined;
  duration: number;
  playContent: (content: ILibraryContent, openSyncBar: boolean, ...devices: Array<IDevice>) => Promise<any>;
  pause: () => Promise<any>;
  stop: () => Promise<any>;
}

/**
 * Creates the context.
 *
 */
const PlaybackContext = createContext<IPlaybackContextStates>({
  isPlaying: false,
  isPaused: false,
  currentTime: 0,
  duration: 0,
  currentContent: undefined,

  playContent: (content: ILibraryContent, openSyncBar, ...devices: Array<IDevice>) => {
    return new Promise<any>(resolve => resolve);
  },

  pause: () => {
    return new Promise<any>(resolve => resolve);
  },
  stop: () => {
    return new Promise<any>(resolve => resolve);
  }
});

/**
 * Wrapper around the application
 */
export const PlaybackProvider: React.FC<React.PropsWithChildren> = props => {
  const currentStoredContent = useMemo(() => {
    let currentStoredApp = browserStorage.getCurrentlyRunningApp();

    if (!currentStoredApp) return false;
    if (currentStoredApp.currentApp === null && currentStoredApp.currentVideo === null) {
      browserStorage.deleteCurrentlyRunningApp();
      return false;
    }

    if (currentStoredApp && currentStoredApp.updatedAt) {
      const difference = Date.now() - new Date(currentStoredApp.updatedAt).getTime();
      const resultInMinutes = Math.round(difference / 60000);
      if (resultInMinutes >= 60) {
        currentStoredApp = null;
      }
    }

    isPausedGlobal = currentStoredApp?.isPaused || false;
    return currentStoredApp;
  }, []);

  const [isPlaying, setIsPlaying] = useState<boolean>(currentStoredContent?.isPlaying || false);
  const [isPaused, setIsPaused] = useState<boolean>(currentStoredContent?.isPaused || false);

  const [currentContent, setCurrentContent] = useState<ILibraryContent>(currentStoredContent || undefined);
  const [currentTime, setCurrentTime] = useState<number>(currentStoredContent?.currentTime || 0);
  const [duration, setDuration] = useState<number>(currentStoredContent?.duration || 0);

  const [currentlyActiveDevices, setCurrentlyActiveDevices] = useState<IDevice[]>([]);

  useEffect(() => {
    if (isPlaying || isPaused) {
      browserStorage.setCurrentlyRunningApp({
        isPlaying,
        isPaused,
        currentTime,
        duration,
        updatedAt: Date.now()
      });
    }
  }, [isPlaying, isPaused, currentTime, duration]);

  useEffect(() => {
    if (currentStoredContent && !timerInterval) {
      createTimer(true);
    }
  }, [currentStoredContent]);

  const getCommand = (contentType: ContentType) => {
    switch (contentType) {
      case "app":
        return CloudCommand.START_APP;

      case "video":
        return CloudCommand.START_VIDEO;

      case "webApp":
        return CloudCommand.START_WEBAPP;
      default:
        return CloudCommand.START_APP;
    }
  };

  const updateActiveDevices = (devices: IDevice[]) => {
    // Combine arrays
    const combinedArray = [...devices, ...currentlyActiveDevices];

    // Remove duplicates
    const uniqueArray = combinedArray.filter((obj, index, self) => index === self.findIndex(o => o.id === obj.id));

    setCurrentlyActiveDevices(uniqueArray);
  };

  const startContent = (content: ILibraryContent, openSyncBar = true, ...devices: Array<IDevice>) => {
    const promises: Array<Promise<any>> = [];
    if (content.contentType) {
      updateActiveDevices(devices);
      const command = getCommand(content.contentType);
      devices.forEach(device => {
        promises.push(ApiDeviceService.executeCommand(device.serialNumber, command, content.uuid, content.packageName));
      });
      if (content.contentType === "video") {
        getVideoDuration(content.file!).then(duration => {
          setDuration(duration);
        });
      }

      return Promise.all(promises).then(() => {
        if (isPaused) {
          setPaused(false);
        } else if (openSyncBar) {
          setCurrentContent(content);
          setPaused(false);
          setIsPlaying(true);
          createTimer();
        }
      });
    }
    return Promise.all(promises).then(() => {});
  };

  const pause = async () => {
    const promises: Array<Promise<any>> = [];
    currentlyActiveDevices.forEach(device => {
      promises.push(ApiDeviceService.executeCommand(device.serialNumber, CloudCommand.TOGGLE_PAUSE));
    });

    await Promise.all(promises);
    setPaused(!isPausedGlobal);
  };

  const stopAllDevices = async () => {
    const promises: Array<Promise<any>> = [];
    currentlyActiveDevices.forEach(device => {
      promises.push(ApiDeviceService.executeCommand(device.serialNumber, CloudCommand.STOP_RUNNING));
    });
    return Promise.all(promises).then(() => {
      reset();
    });
  };

  const setPaused = (state: boolean) => {
    isPausedGlobal = state;
    setIsPaused(state);
  };

  const reset = () => {
    setPaused(false);
    setIsPlaying(false);
    clearTimer();
    setCurrentlyActiveDevices([]);
    browserStorage.deleteCurrentlyRunningApp();
  };

  const intervalFunction = () => {
    if (!isPausedGlobal) setCurrentTime(currentTime => currentTime + 1);
  };

  const createTimer = (dontReset?: boolean) => {
    if (timerInterval && !dontReset) {
      clearInterval(timerInterval);
      setCurrentTime(0);
    }
    timerInterval = setInterval(intervalFunction, 1000);
  };

  const clearTimer = () => {
    if (timerInterval) {
      clearInterval(timerInterval);
      setCurrentTime(0);
    }
  };

  return (
    <PlaybackContext.Provider
      value={{
        isPlaying: isPlaying,
        isPaused: isPaused,
        currentTime: currentTime,
        duration: duration,
        currentContent: currentContent,
        playContent: startContent,
        pause: pause,
        stop: stopAllDevices
      }}
    >
      {props.children}
    </PlaybackContext.Provider>
  );
};

export function usePlayback(): IPlaybackContextStates {
  const context = React.useContext(PlaybackContext);
  if (context === undefined) {
    throw new Error("usePlayback must be used within a PlaybackContext.");
  }

  return context;
}
