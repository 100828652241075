import { AxiosResponse } from "axios";

import { dispatch } from "./http";
import { getCSRFToken } from "../../storage/cookiePlate";

export type AuthSession = {
  status: number;
  data: {
    user: {
      id: number;
      display: string;
      email: string;
      username: string;
    };
  };
  meta: {
    is_authenticated: boolean;
  };
};

/**
 * We can use either `app` or `browser` as client for communication with
 * django allauth. The default should be `browser`.
 *
 */
const Client = {
  APP: "app",
  BROWSER: "browser"
};

const CLIENT = Client.BROWSER;

const AuthProcess = {
  LOGIN: "login",
  CONNECT: "connect"
};

/**
 * Oauth flows we can work with.
 *
 */
const Flows = {
  VERIFY_EMAIL: "verify_email",
  LOGIN: "login",
  LOGIN_BY_CODE: "login_by_code",
  SIGNUP: "signup",
  PROVIDER_REDIRECT: "provider_redirect",
  PROVIDER_SIGNUP: "provider_signup",
  MFA_AUTHENTICATE: "mfa_authenticate",
  REAUTHENTICATE: "reauthenticate",
  MFA_REAUTHENTICATE: "mfa_reauthenticate"
};

export class ApiSocialAuthentication {
  public static getConfig(): Promise<AxiosResponse> {
    return dispatch({
      method: "GET",
      url: `/api/social/${CLIENT}/v1/config`
    });
  }

  public static getSession(): Promise<AxiosResponse<AuthSession>> {
    return dispatch({
      method: "GET",
      url: `/api/social/${CLIENT}/v1/auth/session`
    });
  }

  /**
   * This redirect MUST use the documents submit method to redirect the user to the app.
   *
   * @param providerId
   * @param callbackUrl
   */
  public static redirectToProvider(providerId: string, callbackUrl: string): void {
    const documentForm = document.createElement("form");
    documentForm.method = "POST";
    documentForm.action = `${process.env.API_BASE_URL}/api/social/${CLIENT}/v1/auth/provider/redirect`;

    const formData = {
      provider: providerId,
      process: AuthProcess.LOGIN,
      callback_url: callbackUrl,
      csrfmiddlewaretoken: getCSRFToken()
    };

    for (const k in formData) {
      const inputField = document.createElement("input");
      inputField.type = "hidden";
      inputField.name = k;
      // @ts-ignore
      inputField.value = formData[k];
      documentForm.append(inputField);
    }

    document.body.appendChild(documentForm);
    documentForm.submit();
  }
}
