import { AxiosResponse } from "axios";

import { IConfiguration } from "../../models/configuration";
import { CloudCommand } from "../../constants";
import { dispatch } from "./http";
export interface RemoveShareConfigDTO {
  configId: number;
  organisationIds: number[];
}
export interface ShareConfigDTO {
  configId: number;
  organisationIds: number[];
}
export interface AddContentToConfigDTO {
  configID: number;
  contentID: number;
  origin: "MSB" | "VIL";
}
export interface RemoveContentFromConfigDTO {
  configID: number;
  contentID: number;
  origin: "MSB" | "VIL";
}

export interface TransferContentObject {
  content_id: number;
  origin: string;
}

export interface CreateConfigurationDTO {
  name: string;
  content: Array<number>;
  //content: Array<TransferContentObject>;
  isActive: boolean;
  ownerId: number;
  mdmDeviceIds?: number[];
}

export class ApiConfigurationService {
  public static fetchAllConfigurations(pageParam: URLSearchParams): Promise<AxiosResponse> {
    return dispatch({
      method: "GET",
      url: "/api/v2/mdm/device-configurations/",
      params: pageParam
    });
  }

  public static fetchSingleConfiguration(id: number): Promise<AxiosResponse<IConfiguration>> {
    return dispatch({
      method: "GET",
      url: `/api/v2/mdm/device-configurations/${id}/`
    });
  }

  public static updateSingleConfiguration(id: number, data: any): Promise<AxiosResponse<IConfiguration>> {
    return dispatch({
      method: "PUT",
      url: `/api/v2/mdm/device-configurations/${id}/`,
      data: data
    });
  }

  public static updateConfigData(
    id: number,
    name: string,
    content: Array<number>,
    isActive: boolean,
    ownerId: number,
    mdmDeviceIds?: number[]
  ): Promise<AxiosResponse<IConfiguration>> {
    return dispatch({
      method: "PUT",
      url: `/api/v2/mdm/device-configurations/${id}/`,
      data: {
        name: name,
        ownerId: ownerId,
        contentIds: content,
        activeState: isActive,
        mdmDevices: mdmDeviceIds
      }
    });
  }
  public static createConfiguration(dto: CreateConfigurationDTO): Promise<AxiosResponse> {
    return dispatch({
      method: "POST",
      url: `/api/v2/mdm/device-configurations/`,
      data: {
        name: dto.name,
        ownerId: dto.ownerId,
        contentIds: dto.content,
        activeState: dto.isActive,
        mdmDevices: dto.mdmDeviceIds ? dto.mdmDeviceIds : []
      }
    });
  }

  public static activeConfig(id: number, devices: Array<number>): Promise<AxiosResponse> {
    return dispatch({
      method: "PUT",
      url: `/api/v2/mdm/device-configurations/${id}/activate/`,
      data: { mdmDevices: devices }
    });
  }

  public static addContentToConfiguration(dto: AddContentToConfigDTO): Promise<AxiosResponse> {
    return dispatch({
      method: "POST",
      url: `/api/v2/mdm/device-configurations/${dto.configID}/content/`,
      data: { contentId: dto.contentID, contentOrigin: dto.origin }
    });
  }

  public static removeContentFromConfiguration(dto: RemoveContentFromConfigDTO): Promise<AxiosResponse> {
    return dispatch({
      method: "POST",
      url: `/api/v2/mdm/device-configurations/${dto.configID}/remove-content/`,
      data: { contentId: dto.contentID, contentOrigin: dto.origin }
    });
  }

  public static shareConfiguration(dto: ShareConfigDTO): Promise<AxiosResponse> {
    return dispatch({
      method: "POST",
      url: `/api/v1/mdm/device-configurations/${dto.configId}/add-share/`,
      data: { organisation_ids: dto.organisationIds }
    });
  }

  public static removeShareConfiguration(dto: RemoveShareConfigDTO): Promise<AxiosResponse> {
    return dispatch({
      method: "POST",
      url: `/api/v1/mdm/device-configurations/${dto.configId}/remove-share/`,
      data: { organisation_ids: dto.organisationIds }
    });
  }

  public static deleteConfiguration(id: number): Promise<AxiosResponse> {
    return dispatch({
      method: "DELETE",
      url: `/api/v2/mdm/device-configurations/${id}/`
    });
  }

  public static executeCommand(serialNumber: string, command: CloudCommand, contentUuid?: string): Promise<AxiosResponse> {
    const data: any = {
      command: command
    };
    if (contentUuid) {
      data.contentUuid = contentUuid;
    }
    return dispatch({
      method: "GET",
      url: `/api/v2/mdm/devices/${serialNumber}/command/`,
      data: data
    });
  }
}
