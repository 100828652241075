import type { AxiosRequestConfig, AxiosRequestHeaders, AxiosResponse } from "axios";
import _ from "lodash";
import axios from "axios";
import https from "https";

import { browserStorage, deleteBrowserData } from "../../storage/localStorage";

type HttpMethod = "GET" | "POST" | "PUT" | "PATCH" | "DELETE" | "HEAD";
type ResponseType = "blob" | "json";

interface RequestParams {
  method: HttpMethod;
  url: string;
  data?: any;
  params?: URLSearchParams;
  jwtToken?: string;
  headers?: AxiosRequestHeaders;
  responseType?: ResponseType;
  addAuthHeader?: boolean;
}

/**
 * Config for axios.
 */
const httpConfig: AxiosRequestConfig = {
  baseURL: process.env.API_BASE_URL,
  responseType: "json",
  httpsAgent: new https.Agent({
    // rejectUnauthorized: APPLICATION_ENV_IS_PROD
  }),
  withCredentials: true,
  validateStatus: function (status) {
    return status >= 200 && status < 300; // default
  }
};

/**
 * Returns an axios instance which we use
 * to make our requests.
 *
 * @returns {instance} axios
 */
const http = axios.create(httpConfig);

/**
 * `dispatch` is a generic way to make api calls.
 *
 * Usage: dispatch({
 *  method: "get",
 *  url: "/api/path"
 * })
 *
 * Optional you can pass data to `dispatch`.
 *
 * @param {String} method
 * @param {String} url
 * @param {object} data
 * @param {Object} params
 * @param {Object} headers
 * @param {String} responseType
 * @returns {Promise} Promise
 */
export function dispatch({
  method,
  url,
  data,
  params,
  jwtToken,
  headers = {},
  responseType = "json"
}: RequestParams): Promise<AxiosResponse> {
  if (jwtToken) {
    headers.Authorization = `Bearer ${jwtToken}`;
  } else if (typeof window !== "undefined") {
    // JWT Token is only available in the user's browser, not on the server.
    const token = browserStorage.getAccessToken();
    if (token) {
      headers.Authorization = `Bearer ${token}`;
    }
  }

  return http({ method, url, headers, data, params, responseType })
    .then(res => {
      return res;
    })
    .catch(error => {
      if (_.get(error, "response.status") === 401) {
        // It seems the user is not signed in anymore.
        // Delete local storage and redirect user to login page.
        deleteBrowserData();

        // // Redirect user to start page.
        // setTimeout(() => {
        //   window.location.href = "/";
        // }, 500);
      }

      return error.response;
    });
}
